import React, { Component } from "react";
import {withRouter} from 'react-router-dom'
import cookie from "react-cookies";
import Logo from '../assets/images/logo.png'
import { Lang } from "../Common/Trns";
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';


class index extends Component {

  state={
    name:cookie.load('name'),
    clientName:cookie.load('clientName')
    
  }
    logOut=()=>{
      cookie.remove('name', { path: '/' })
      cookie.remove("api_token", { path: '/' });
      cookie.remove("userID", { path: '/' })
      cookie.remove('id', { path: '/' })
      cookie.remove('role', { path: '/' })
      cookie.remove('clientName', { path: '/' })
        this.props.history.push('/')
        window.location.reload()
    }

    facilitatorLeft=()=>{
      //this.props.history.push('/Session')
       // window.location.href('/Session')
        window.location.assign("https://digital.wicompli.com/Session")
    }
  goodBye=()=>{
    
       const {status}=this.props
       console.log(status)
       if(status=='Preparing'||status=='Completed'){
           this.facilitatorLeft()
      

       }else if(status=='In Progress'||status=='Open For Participants'){
        Modal.confirm({
          title: 'Leave Session?',
          icon: <ExclamationCircleOutlined />,
          content: 'Are you sure you want to leave this session? If participants are on this session they will get a notification about your absence.',
          okText: Lang['yes'][this.state.language],
          cancelText: Lang['No'][this.state.language],
          cancelButtonProps:{className:"btn btn-secondary"},
          onOk:()=>this.facilitatorLeft()
        })
       

        // this.props.history.push('/Session')

       }
  }
  render() {
    const{name}=this.state
    return (
      <div>
        <header class="nav-section">
          <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
              <a class="navbar-brand" onClick={()=>this.goodBye()}>
                <img src={Logo} class="img-fluid" />
              </a>

              <div class="header-link float-right ml-auto">
    <h5 class="welcome"> Welcome, {name}!</h5>
    {
       this.state.clientName==null||this.state.clientName=='null'?null:<p className='mb-0'>{this.state.clientName}</p>}

   
                <a style={{cursor:"pointer"}} onClick={this.logOut}>Logout</a>
              </div>
            </nav>
          </div>
        </header>
      </div>
    );
  }
}

export default withRouter(index)
