import { message } from "antd";

 export const Lang ={
     sessionTitle:{
         eng:"WiCompli Report",
         fr:"WiCompli Report"
     },mySessions:{
         eng:"My Sessions",
         fr:"My Sessions"
     },searchTitle:{
         eng:"Search Title",
         fr:"Search Title"
     },
     SearchTitle:{
         eng:"Search Title",
         fr:"Search Title"
     },
     SearchDate:{
         eng:"Date Range",
         fr:"Date Range"
     },
     selectFromDate:{
         eng:"Select From Date",
         fr:"Select From Date"
     },
     selectToDate:{
         eng:"Select To Date",
         fr:"Select To Date"
     },
     SearchDateTo:{
      eng:"To",
      fr:"To"
  },
     SearchBtn:{
      eng:"Search",
      fr:"Search"
     },
     Date:{
        eng:"Date",
        fr:"Date"
     },
     Title:{
        eng:"Title",
        fr:"Title"
     },client:{
        eng:"Client",
        fr:"Client"
     },
     Participant:{
        eng:"Participant",
        fr:"Participant"
     },Teams:{
        eng:"Teams",
        fr:"Teams"
     },
     NoOfParticipants:{
        eng:"Participants",
        fr:"Participants"
     },
     Team:{
        eng:"Team",
        fr:"Team"
     },
     Status:{
        eng:"Status",
        fr:"Status"
     },
     Action:{
        eng:"Action",
        fr:"Action"
     },

     //login
     loginTile:{
        eng:"Welcome to Facilitator Login",
        fr:"Welcome to Facilitator Login"
     },
     userName:{
        eng:"Enter Your Email address",
        fr:"Enter Your Email address"
     },
     enterName:{
        eng:"Enter Your Name",
        fr:"Enter Your Name"
     },
     password:{
        eng:"Password",
        fr:"Password"
     },
     login:{
        eng:"login",
        fr:"login"
     },
     forgetPassword:{
        eng:"Forgot Password?",
        fr:"Forgot Password?"
     },
     //Question Screen
     icoTab:{
        eng:"Non-Compliance",
        fr:"Non-Compliance"
     },
     oppsTab:{
        eng:"Opps And Threats",
        fr:"Opps And Threats"
     },
     wiTab:{
        eng:"Wi",
        fr:"Wi"
     },
     compliTab:{
        eng:"Compli",
        fr:"Compli"
     },
     //
     markBonusQuestion:{
        eng:"Mark Bonus Question",
        fr:"Mark Bonus Question"
     },
     cancel:{
        eng:"Cancel",
        fr:"Cancel"
     },
     //Participant Pannel
       partHead:{
          eng:"Join WiCompli Session",
          fr:"Join WiCompli Session"
       },
       privacyNotice:{
         eng:"Privacy Notice",
         fr:"Privacy Notice"
      },
       partTitle:{
         eng:"Title:",
         fr:"Title:"
       },
       partFac:{
          eng:"Facilitator: ",
          fr:"Facilitator: "
       },
       joinSession:{
         eng:"join Session",
         fr:"join Session"
       },
       errorMsg:{
          eng:"This session does not have room for additional participants.",
          fr:"This session does not have room for additional participants."
       },
       rules:{
          eng:"Rules of the Game",
          fr:"Rules of the Game"
       },
       waitMsg:{
          eng:"Wait till the facilitator lets you in",
          fr:"Wait till the facilitator lets you in"
       },proceedBtn:{
          eng:"Ok, Proceed",
          fr:"Ok, Proceed"
       },proceedTxt:{
         eng:"Proceed",
         fr:"Proceed"
      },Q1:{
          eng:`What was the most important area of learning for you from the session?`,
          fr:`What was the most important area of learning for you from the session?`,
       },
       Q2:{
         eng:`Identify one thing you  / your team will start doing differently`,
         fr:`Identify one thing you  / your team will start doing differently`,
      },
      Q3:{
         eng:`Identify one thing that you / your team will continue doing to maintain compliance`,
         fr:`Identify one thing that you / your team will continue doing to maintain compliance`,
      },
      Q4:{
         eng:`Identify one thing you / your team will stop doing to avoid non - compliance`,
         fr:`Identify one thing you / your team will stop doing to avoid non - compliance`,
      },
      RulesHeading:{
         eng:`Welcome to WiCompli a fun and innovative way to learn about Data Protection.  Here is a quick introduction to the rules.  `,
         fr: `Welcome to WiCompli a fun and innovative way to learn about Data Protection.  Here is a quick introduction to the rules. `
      },
      Rule1st:{
         eng:`You will be placed in a team with some of your colleagues. Your team will have a maximum of 4 players. Choose a team name and give this to the facilitator. The objective is to travel around the board and accumulate as many points as possible. The team with the most points at the end of the session are the winners. You gain points by responding to questions and scenarios from 4 cards decks. The card decks are`,
         fr:`You will be placed in a team with some of your colleagues. Your team will have a maximum of 4 players. Choose a team name and give this to the facilitator. The objective is to travel around the board and accumulate as many points as possible. The team with the most points at the end of the session are the winners. You gain points by responding to questions and scenarios from 4 cards decks. The card decks are`
      },Rule3rd:{
         eng:'There will also be bonus questions where the first team to press the buzzer gets the chance to answer a question and win extra points.',
         fr:'There will also be bonus questions where the first team to press the buzzer gets the chance to answer a question and win extra points.'
      },Rule4th:{
         eng:'You do not lose any points if your answers are not quite correct so feel free to share your thoughts and play to win!',
         fr:'You do not lose any points if your answers are not quite correct so feel free to share your thoughts and play to win!'
      },
      Rule2nd:{
         eng:`When you have control of the board, you will have the first opportunity to respond to the question, but the other teams will also have the opportunity to contribute and gain points. The facilitator will provide feedback on your answers and award points, including bonus points for the quality and depth of contributions.`,
         fr:`When you have control of the board, you will have the first opportunity to respond to the question, but the other teams will also have the opportunity to contribute and gain points. The facilitator will provide feedback on your answers and award points, including bonus points for the quality and depth of contributions.`
      }, Rule1stLi:{
         eng:`1. Compli  - factually based questions, that have specific answers`,
         fr:`1. Compli  - factually based questions, that have specific answers`
      }, Rule2ndLi:{
         eng:`2. Wi – what’s the issue and what would you do? - scenario-based questions and you will explore the risks and how you will handle these scenarios.`,
         fr:`2. Wi – what’s the issue and what would you do? - scenario-based questions and you will explore the risks and how you will handle these scenarios.`
      },Rule3rdLi:{
         eng:`3. Enforcement - scenarios that present situations where there are issues of non – compliance and the regulator takes action. These are used as talking points.`,
         fr:`3. Enforcement - scenarios that present situations where there are issues of non – compliance and the regulator takes action. These are used as talking points.`
      },Rule4thLi:{
         eng:`4. Opportunities & Threats – scenarios that present opportunities for, or threats to, compliance. These will be used as talking points`,
         fr:`4. Opportunities & Threats – scenarios that present opportunities for, or threats to, compliance. These will be used as talking points`
      },
      Board:{
         eng:'High Contrast Board',
         fr:'High Contrast Board'
      },
      Points:{
         eng:'Points',
         fr:'Points'
      },
      partFeedBackForm:{
       eng:'Participant Outcome from',
       fr:'Participant Outcome from'
      },
      SubmitFeedback:{
         eng:'Submit Outcomes',
         fr:'Submit Outcomes'
      },
      SubmitFeedbackText:{
         eng:'Are you sure you want to submit the outcomes?',
         fr:`Are you sure you want to submit the outcomes?`
      },
      sessionFeedBack:{
      eng:'Outcomes',
      fr:'Outcomes'
      },
      enterEmail:{
      eng:'Please Enter Email',
      fr:'Please Enter Email'
      },
      thankyouFeedback:{
      eng:'Thankyou for your outcomes',
      fr:'Thankyou for your outcomes'
      },
      sessionEnded:{
         eng:'This session has already ended',
         fr:'This session has already ended'
      },
      roomFull:{
         eng:'Max capacity for the session has been reached, please contact the session facilitator',
         fr:'Max capacity for the session has been reached, please contact the session facilitator'
      },
      rollDice:{
         eng:'Dice roll',
         fr:'Dice roll'
      },
      rollTheDice:{
         eng:'Roll the dice',
         fr:'Roll the dice'
      },
      refMaterial:{
         eng:'Reference Material',
         fr:'Reference Material'
      },
      bonusQuestion:{
         eng:'Bonus Question',
         fr:'Bonus Question'
      },bonusQuestionHeading:{
         eng:'Please select Card Deck for bonus question',
         fr:'Please select Card Deck for bonus question'
      },
      participantResponses:{
         eng:'Participant Responses',
         fr:'Participant Responses',
      },
      yes:{
         eng:'yes',
         fr:'yes'
      },
      team:{
         eng:"Team",
         fr:'Team'
      },
      No:{
         eng:'No',
         fr:'No'
      },
      thankyou:{
         eng:'Thank you for participating in this session, Your feedback has been recorded!',
         fr:"Thank you for participating in this session, Your feedback has been recorded! "
      },
       endGame:{
          eng:`End Game`,
          fr:`End Game`
       },
       contentEndGame:{
          eng:`Are you sure you want to End the current session?`,
          fr:`Are you sure you want to End the current session?`
       },
     contentPointsAssignment:{
          eng:`You have not assigned any points on this question. Are you sure you would like to move to the next turn?`,
          fr:`You have not assigned any points on this question. Are you sure you would like to move to the next turn?`
       },
       endSession:{
          eng:`End Session`,
          fr:`End Session`
       },
       upTo:{
          eng:'Win / Lose',
          fr:'Win / Lose'
       },
       Points_Available:{
          eng:'Points',
          fr:'Points'
       },
       QuestionNotAvl:{
         eng:'No Questions Available',
         fr:'No Questions Available'
       },
       Possible_Answer:{
          eng:'Possible Answer / Talking Points',
          fr:'Possible Answer / Talking Points'
       },
       Ending_the_Game:{
          eng:'Ending the Game',
          fr:'Ending the Game'
       },
       SAVE_FACILITATOR_NOTES:{
          eng:'SAVE FACILITATOR NOTES',
          fr:`SAVE FACILITATOR NOTES`
       },FACILITATOR_NOTES:{
          eng:'FACILITATOR NOTES',
          fr:`FACILITATOR NOTES`
       },
       Confirmation:{
          eng:"Confirmation",
          fr:"Confirmation"
       },Notes_Editable:{
          eng:'These Notes are not editable.',
          fr:'These Notes are not editable.'
       },Notes_Message:{
          eng:`Are you sure you want to save these notes with this session?`,
          fr:`Are you sure you want to save these notes with this session?`
       },Roll_Again:{
          eng:'Roll Again',
          fr:'Roll Again'
       },
       Taking_Turn:{
          eng:'Taking Turn',
          fr:'Taking Turn'
       },
       Pick_Card:{
          eng:'Pick a card',
          fr:'Pick a card',
       },Skip_Turn:{
       eng:'Skip Turn',
       fr:'Skip Turn'
       },
       overRide:{
          eng:'Override',
          fr:'Override'
       },Assign_Points:{
          eng:'Assign Points',
          fr:"Assign Points"
       },
       Next_Turn:{
          eng:'Next Turn',
          fr:'Next Turn'
       },Text:{
          eng:'Text',
          fr:'Text'
       },
       Video:{
         eng:'Video',
         fr:'Video'
       },
       Image:{
          eng:'Images',
          fr:'Images'
       },Bonus_Question_Not_Available:{
          eng:'Bonus Question Not Available',
          fr:'Bonus Question Not Available'
       },Reference_Text_Not_Available:{
          eng:'Reference Text Not Available',
          fr:'Reference Text Not Available'
       },
       Reference_Video_Not_Available:{
          eng:'Reference Video Not Available',
          fr:'Reference Video Not Available'
       },
       Reference_Images_Not_Available:{
          eng:"Reference Images Not Available",
          fr:'Reference Images Not Available'
       },
       welcome:{
          eng:'Welcome, ',
          fr:'Welcome, '
       },
       sessionWith:{
         eng:'Session with ',
         fr:'Session with '
       },
       gameOver:{
          eng:'This session has ended',
          fr:'This session has ended'
       },
       cleint:{
          eng:'Client',
          fr:'Client'
       },
       Created_On:{
               eng:'Created On',
               fr:'Created On'
       },
       facilitatorJoin:{
          eng:'Facilitator has connected to the game',
          fr:`Facilitator has connected to the game`
       },
       facilitatorDisconnect:{
         eng:'Facilitator has disconnected from the game',
         fr:`Facilitator has disconnected from the game`
      },
      youRemoved:{
         eng:`You have been removed from the game`,
         fr:`You have been removed from the game`
      },
      ParticipantJoinedDesc:{
         eng:`has joined the game`,
         fr:`has joined the game`
      },ParticipantJoined:{
         eng:`Participant Joined`,
         fr:`Participant Joined`
      }, ParticipantRemoved:{
         eng:`Participant Removed`,
         fr:`Participant Removed`
      },ParticipantLeft:{
         eng:` has left the game`,
         fr:` has left the game`
      },
      Participant:{
         eng:'Participant',
         fr:'Participant'
      },
      facilitator:{
         eng:'Facilitator',
         fr:`Facilitator`
      },
      save:{
         eng:'Save',
         fr:'Save'
      }
      ,Confirm:{
         eng:'Confirm',
         fr:'Confirm'
      },poweredBy:{
         eng:'Powered By: ',
         fr:'Powered By: '
      },
      CopyRight:{
         eng:"Copyright",
         fr:'Copyright'
      },
      Platform:{
         eng:'Platform built by ',
         fr:'Platform built by '
      },
      decideRisk:{
         eng:"Select Path",
         fr:"Select Path"
      },
      buzzerText:{
         eng:"Buzzz",
         fr:"Buzzz",
      },
      contentJailConfirmation1:{
         eng:`Are you sure you want to send Team `,
         fr:`Are you sure you want to send Team `
      },
      contentJailConfirmation2:{
         eng:` to Jail?`,
         fr:`to Jail?`
      },
      pressBuzzer:{
         eng:`Press here to answer`,
         fr:`Press here to answer`,
      },
      
      bonus:{
         eng:`Bonus question`,
         fr:`Bonus question`,
      },
      bonusNotification:{
         eng:`The next question is a bonus question. Please press the red buzzer if you wish to answer. Whoever presses the buzzer first, gets the chance to answer the question first, on behalf of their team`,
         fr:`The next question is a bonus question. Please press the red buzzer if you wish to answer. Whoever presses the buzzer first, gets the chance to answer the question first, on behalf of their team`,
      },
      bwd:{
         eng:`Backward`,
         fr:`Backward`
      },
      fwd:{
         eng:`Forward`,
         fr:`Forward`
      },
      assesmentDescription:{
         eng:`Now you have the option to complete the online assessment by clicking `,
         fr:`Now you have the option to complete the online assessment by clicking `
      },
      assesmentDescription2:{
         eng:` or following the link to the assessment on your E learning portal.`,
         fr:` or following the link to the assessment on your E learning portal.`
      }
   
 }


